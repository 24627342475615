<template>
  <div>{{ status }}</div>
</template>

<script>
import { activateAccount } from '../shared/services/auth'

export default {
  name: 'AuthConfirmEmail',
  data() {
    return {
      status: ''
    }
  },
  created() {
    this.confirmEmail()
  },
  methods: {
    async confirmEmail() {
      const { token } = this.$route.params
      this.status = 'Activating...'
      await activateAccount(token)
          .then(() => {
            this.status = 'Activated! Redirect to login in 3 sec'
            setTimeout(() => {
              this.$router.push({name: 'login'})
            }, 3000)
          })
          .catch((error) => {
        this.status = error
      })
    }
  }
}
</script>
